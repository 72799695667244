/* You can add global styles to this file, and also import other style files */
$primary: #0073ac;
$font-size-base: .875rem;

@import 'bootstrap/scss/bootstrap';

$font-family-base: 'Lato, ' + #{$font-family-base};

.border-dashed {
  border-style: dashed !important;
}
.btn-hover-danger:hover {
  border-color: var(--bs-danger);
  background-color: var(--bs-danger);
  &[class*="btn-outline"] {
    border-color: var(--bs-danger);
    color: var(--bs-danger);
    background-color: transparent;
  }
}
.btn-outline-primary {
  &:hover {
    fa-icon {
      color: rgb(255,255,255) !important;
    }
  }
}
